<template>
  <div class="wrap">
    <div class="banner">
      <div class="swiper-container swiper-banner">
        <div class="swiper-wrapper">
          <div class="swiper-slide swiper-slide1" :style="`background-image: url(${require('@/assets/images/rdc-banner.jpg')})`" @click="goPage('rdc')">
          </div>
          <div class="swiper-slide swiper-slide2" :style="`background-image: url(${require('@/assets/images/dsp-banner.jpg')})`" @click="goPage('dsp')"></div>
          <div class="swiper-slide swiper-slide3" :style="`background-image: url(${require('@/assets/images/bap-banner.jpg')})`" @click="goPage('bap')"></div>
					<div class="swiper-slide swiper-slide4" :style="`background-image: url(${require('@/assets/images/lmp-banner.jpg')})`" @click="goPage('lmp')"></div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="info-block-1 w-1200">
      <div class="title">数邦科技的优势</div>
      <div class="sub-title">帮助数据团队有效的降低工作成本和数据维护成本<br/>挖掘数据价值，为企业决策提供数据支撑</div>
      <div class="info-box">
        <div class="info-item">
          <img src="~@/assets/images/info-icon-1.png"/>
          <p>兼容开源软件框架</p>
          <div class="tip">
            完美兼容开源Hadoop，减少企业服务搭建和数据迁移成本，在当前环境中即可快速完成部署操作
          </div>
        </div>
        <div class="info-item">
          <img src="~@/assets/images/info-icon-2.png"/>
          <p>数据全生命周期开发规范</p>
          <div class="tip">
            依靠强大的引擎完成智慧开发赋能，支持开发规范自动检查，同源导入实时预警，显著提高开发质量和效率，降低管理成本
          </div>
        </div>
        <div class="info-item">
          <img src="~@/assets/images/info-icon-3.png"/>
          <p>统一数据资产管理</p>
          <div class="tip">
            全链路资产管理能力，实现多种类型资产的快速接入与展示，构建组织可视化的知识图谱，基于自身实践沉淀的知识经验，量化资产价值
          </div>
        </div>
        <div class="info-item">
          <img src="~@/assets/images/info-icon-4.png"/>
          <p>全域数据治理保障</p>
          <div class="tip">
            强大的数据综合治理能力，可视化方式配置全链路的多行多列数据质量，行列级别的安全管控及风险报告，满足企业更加丰富的场景需求
          </div>
        </div>
      </div>
    </div>
    <div class="info-block-2">
      <div class="title">实时数据处理DU-RDC</div>
      <div class="sub-title">数据全生命周期智能管理服务</div>
      <div class="info-box w-1200">
        <div class="info-item">
          <img src="~@/assets/images/info2-icon-1.png"/>
          <p>响应</p>
          <div class="tip">
            支持秒级实时数据同步<br/>自动化异常处理机制
          </div>
        </div>
        <div class="info-item">
          <img src="~@/assets/images/info2-icon-2.png"/>
          <p>捕获</p>
          <div class="tip">
            实时捕获新增/变更<br/>删除数据、历史数据同步
          </div>
        </div>
        <div class="info-item">
          <img src="~@/assets/images/info2-icon-3.png"/>
          <p>写入</p>
          <div class="tip">
            实时数据写入中间件、传统数据库<br/>主流大数据库、主流数据湖等
          </div>
        </div>
        <div class="info-item">
          <img src="~@/assets/images/info2-icon-4.png"/>
          <p>异常</p>
          <div class="tip">
            提供及时的元数据变更提示<br/>以及数据异常处理机制  
          </div>
        </div>
      </div>
      <div class="detail-info-box w-1200">
        <img src="~@/assets/images/rdc-index.png"/>
        <ul class="detail-info">
          <li>
            <img src="~@/assets/images/info2-icon-5.png"/>
            <span>数据库底层信息分析技术</span>
          </li>
          <li>
            <img src="~@/assets/images/info2-icon-6.png"/>
            <span>分布式集群化容错机制，<br/>提供实例级别的负载均衡，达到高可用</span>
          </li>
          <li>
            <img src="~@/assets/images/info2-icon-7.png"/>
            <span>双重加密技术，无缝衔接历史数据与增量数据</span>
          </li>
          <li>
            <img src="~@/assets/images/info2-icon-8.png"/>
            <span>独有核心技术，一次底层信息分析，<br/>捕获所有需要的数据</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="info-block-3 w-1200">
      <div class="title">
        机器学习平台DU-LMP 
      </div>
      <div class="info-box">
        <div class="info-item">
          <img src="~@/assets/images/icon-text-1.png"/>
        </div>
        <div class="info-item">
          <img src="~@/assets/images/icon-text-2.png"/>
        </div>
        <div class="info-item">
          <img src="~@/assets/images/icon-text-3.png"/>
        </div>
        <div class="tip">
          机器学习模型开发平台，围绕模型开发、模型服务、模型生命周期管理三个主题，构建统一的机器学习模型开发平台。服务于模型开发工程师、使用模型服务的业务开发工程师、测试工程师、运维工程师等，一站式、方便快捷地开发应用模型，服务于各类业务场景。
        </div>
      </div>
      <img src="~@/assets/images/lmp.png"/>
    </div>
    <div class="info-block-4 w-1200">
      <div class="title">数据服务DU-DSP</div>
      <img src="~@/assets/images/dsp.png"/>
      <div class="info-box">
        <div class="info-item">
          <p>秒级响应</p>
          <div class="tip">
            接口使用批量预处理，流式增量计算、大数据等技术手段，确保交易能够在1秒内响应并返回调用方
          </div>
        </div>
        <div class="info-item">
          <p>易于对接</p>
          <div class="tip">
            可在线查看接口文档，以及测试报文，响应报文，确保客户方开发人员能够快速对接
          </div>
        </div>
        <div class="info-item">
          <p>交互安全</p>
          <div class="tip">
            使用签名认证、签名加密、关键信息不可逆加密等技术，确保客户信息不泄露
          </div>
        </div>
        <div class="info-item">
          <p>自主测试</p>
          <div class="tip">
            客户可自主设定挡板规则，或增加挡板数据，为测试提供定制结果
          </div>
        </div>
      </div>
    </div>
    <div class="info-block-5">
      <div class="w-1200">
        <div class="title">行业精细化解决方案</div>
        <div class="info-box">
          <div class="type-box">
            <div class="type-item" @mouseover="onType(1,true)" @mouseleave="onType(1,false)">
              <img src="~@/assets/images/info5-icon-1.png"/>
              <p>金融</p>
            </div>
            <div class="type-item" @mouseover="onType(2,true)" @mouseleave="onType(2,false)">
              <img src="~@/assets/images/info5-icon-2.png"/>
              <p>新零售</p>
            </div>
            <div class="type-item" @mouseover="onType(3,true)" @mouseleave="onType(3,false)">
              <img src="~@/assets/images/info5-icon-3.png"/>
              <p>汽车</p>
            </div>
            <div class="type-item" @mouseover="onType(4,true)" @mouseleave="onType(4,false)">
              <img src="~@/assets/images/info5-icon-4.png"/>
              <p>文旅</p>
            </div>
            <div class="type-item" @mouseover="onType(5,true)" @mouseleave="onType(5,false)">
              <img src="~@/assets/images/info5-icon-5.png"/>
              <p>泛互联网</p>
            </div>
          </div>
          <div class="tip">
						{{solutionList[solutionIndex]}}
          </div>
        </div>
        <div class="right-info-box">
          <div class="swiper-container swiper5">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="type-swiper-box">
                  <div class="title">金融</div>
                  <div class="content">金融行业解决方案团队为金融机构提供MAU增长、业务增长、降本增效等多种解决</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="type-swiper-box">
                  <div class="title">新零售</div>
                  <div class="content">数字化运营助力品牌营收增长，打造数据驱动全域运营闭环。</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="type-swiper-box">
                  <div class="title">汽车</div>
                  <div class="content">提升车主全生命周期价值，驱动业务KPI指标增长，专属车企的数字化营销与运营平台。</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="type-swiper-box">
                  <div class="title">文旅</div>
                  <div class="content">驱动文旅关键业务指标提升，专为文娱打造的数据分析运营平台。</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="type-swiper-box">
                  <div class="title">泛互联网</div>
                  <div class="content">营销、产品、售后三位一体全面驱动业务增长，为企服全链路业务打造的数字化经营平台。</div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
          <div class="right-ladder-img">
          </div>
        </div>
      </div>
    </div>
    <div class="info-block-6 w-1200">
      <div class="title">优秀合作案例</div>
      <div class="info-box">
        <div class="info-item">
          <img src="~@/assets/images/case-back1.png"/>
          <div class="content-box">
            <img src="~@/assets/images/bank.png"/>
            <div class="text-box">
              <div class="name">重庆某知名银行大数据实时风控</div>
              <div class="describe">数邦科技为重庆某知名银行提供实时数据捕获，进行实时数据处理计算分析，为其建设可视化的数字运营分析系统。 </div>
              <div class="times-up-box">
                <div class="times-up-item">
                  <strong>5倍</strong>
                  <p>数据覆盖率提升</p>
                </div>
                <div class="times-up-item">
                  <strong>15倍</strong>
                  <p>数据运算效率</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-item">
          <img src="~@/assets/images/case-back2.png"/>
          <div class="content-box">
            <img src="~@/assets/images/bank.png"/>
            <div class="text-box">
              <div class="name">重庆电力</div>
              <div class="describe">数邦科技为重庆电力提供大数据分析，管理可视化等项目支持服务。 </div>
              <div class="times-up-box">
                <div class="times-up-item">
                  <strong>3倍</strong>
                  <p>数据覆盖率提升</p>
                </div>
                <div class="times-up-item">
                  <strong>11倍</strong>
                  <p>数据运算效率</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <!-- <div class="dynamic-box">
        <div class="dynamic-item">
          <div class="tag">公司动态</div>
          <div class="name">精选产品，限时免费</div>
          <p class="describe">帮助企业级合作伙伴提升数据获取能力、商业变现能力及市场竞争力。</p>
        </div>
        <div class="dynamic-item">
          <div class="tag">公司动态</div>
          <div class="name">精选产品，限时免费</div>
          <p class="describe">帮助企业级合作伙伴提升数据获取能力、商业变现能力及市场竞争力。</p>
        </div>
        <div class="dynamic-item">
          <div class="tag">公司动态</div>
          <div class="name">精选产品，限时免费</div>
          <p class="describe">帮助企业级合作伙伴提升数据获取能力、商业变现能力及市场竞争力。</p>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  data(){
    return {
      bannerSwiper: null,
      swiper5: null,
			solutionIndex: 0,
			solutionList: ['金融行业解决方案团队为金融机构提供MAU增长、业务增长、降本增效等多种解决', '数字化运营助力品牌营收增长，打造数据驱动全域运营闭环。', '提升车主全生命周期价值，驱动业务KPI指标增长，专属车企的数字化营销与运营平台。', '驱动文旅关键业务指标提升，专为文娱打造的数据分析运营平台。', '营销、产品、售后三位一体全面驱动业务增长，为企服全链路业务打造的数字化经营平台。']
    }
  },
  mounted(){
    this.bannerSwiper = new Swiper('.swiper-banner', {
      pagination: {
        el: '.swiper-pagination',
        clickable :true
      },
      loop: true,
      autoplay : {
        delay:3000
      }
    })
    for(let i=0;i<this.bannerSwiper.pagination.bullets.length;i++){
      this.bannerSwiper.pagination.bullets[i].onmouseover=function(){
        this.click();
      };
    }
    this.swiper5 = new Swiper('.swiper5', {
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction'
      },
      loop: true
    })
  },
  methods: {
    onType(index){
			this.solutionIndex = index - 1
      this.swiper5.slideTo(index, 500, false)
    },
		goPage(name){
			this.$router.push({
				path: '/' + name
			})
		}
  }
}
</script>
<style lang="scss" scoped>
.banner {
  text-align: center;
  overflow: hidden;
  ::v-deep .swiper-container {
    height: 621px;
    .swiper-slide {
			cursor: pointer;
      background-position: center;
      background-repeat: no-repeat;
    }
    .swiper-pagination-bullet {
      position: relative;
      bottom: 8px;
      width: 54px;
      height: 3px;
      border-radius: inherit;
      // background-color: #fff;
    }
  }
}
.info-block-1 {
  padding-top: 59px;
  padding-bottom: 79px;
  padding-left: 26px;
  padding-right: 32px;
  text-align: center;
  .title {
    font-size: 18px;
    font-weight: 400;
    color: #040404;
    line-height: 20px;
  }
  .sub-title {
    margin-top: 39px;
    font-size: 30px;
    font-weight: 500;
    color: #040404;
    line-height: 36px;
  }
  .info-box {
    overflow: hidden;
    margin-top: 57px;
    .info-item {
      float: left;
      width: 25%;
      img {
        width: 70px;
      }
      p {
        margin-top: 19px;
        font-size: 18px;
        font-weight: 600;
        color: #040404;
        line-height: 20px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          width: 48px;
          height: 4px;
          left: 50%;
          margin-left: -24px;
          bottom: -24px;
          background-image: url('~@/assets/images/line.png');
          background-repeat: no-repeat;
          background-size: 48px;
        }
      }
      .tip {
        margin-top: 44px;
        font-size: 14px;
        font-weight: 400;
        color: #777777;
        line-height: 20px;
        width: 251px;
        display: inline-block;
      }
    }
  }
}
.info-block-2 {
  padding-top: 50px;
  padding-bottom: 40px;
  background-color: #242831;
  text-align: center;
  .title {
    font-size: 48px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .sub-title {
    margin-top: 18px;
    font-size: 20px;
    font-weight: 300;
    color: #FFFFFF;
    opacity: 0.5;
  }
  .info-box {
    overflow: hidden;
    padding: 0 10px;
    margin-top: 50px;
    .info-item {
      float: left;
      width: 275px;
      height: 267px;
      background: rgba(255,255, 255, 0.1);
      border-radius: 10px;
      box-sizing: border-box;
      border: 1px solid #40444C;
      margin: 0 10px;
      img {
        margin-top: 40px;
        width: 80px;
      }
      p {
        margin-top: 20px;
        font-size: 30px;
        font-weight: 600;
        color: #FFFFFF;
        opacity: 0.8;
      }
      .tip {
        margin-top: 19px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        opacity: 0.5;
      }
    }
  }
  .detail-info-box {
    overflow: hidden;
    margin-top: 21px;
    >img {
      width: 687px;
      float: left;
    }
    .detail-info {
      margin-left: 650px;
      border: 2px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      border-radius: 10px;
      position: relative;
      top: 19px;
      margin-right: 20px;
      li {
        box-sizing: border-box;
        text-align: left;
        height: 93px;
        line-height: 88px;
        border-bottom: 2px dashed rgba(255, 255, 255, 0.2);
        &:last-child {
          border-bottom: none;
        }
        >img {
          vertical-align: middle;
          width: 32px;
          margin-left: 42px;
        }
        span {
          vertical-align: middle;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.2;
          color: #FFFFFF;
          opacity: 0.8;
          margin-left: 22px;
          display: inline-block;
        }
      }
    }
  }
}
.info-block-3 {
  overflow: hidden;
  padding-top: 89px;
  padding-bottom: 104px;
  padding-left: 18px;
  padding-right: 82px;
  .title {
    font-size: 48px;
    font-weight: 600;
    color: #040404;
  }
  .info-box {
    float: left;
    overflow: hidden;
    margin-top: 64px;
    width: 600px;
    .info-item {
      float: left;
      width: 103px;
      margin-right: 113px;
      >img {
        width: 102px;
      }
      &:nth-child(3) {
        margin-right: 0;
      }
    }
    
    .tip {
      margin-top: 65px;
      float: left;
      font-size: 22px;
      font-weight: 400;
      color: #242831;
      line-height: 32px;
      width: 528px;
    }
  }
  >img {
    float: right;
    position: relative;
    top: 30px;
    width: 433px;
  }
}
.info-block-4 {
  padding: 70px 79px 70px 58px;
  overflow: hidden;
  .title {
    font-size: 48px;
    font-weight: 600;
    color: #040404;
    padding-left: 542px;
  }
  >img {
    float: left;
    margin-top: 65px;
    width: 460px;
  }
  .info-box {
    margin-left: 525px;
    width: 600px;
    margin-top: 41px;
    .info-item {
      float: left;
      text-align: center;
      width: 240px;
      height: 240px;
      border-radius: 10px;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      &:nth-child(1),&:nth-child(2) {
        margin-bottom: 40px;
      }
      &:nth-child(odd) {
        margin-right: 40px;
      }
      p {
        font-size: 30px;
        font-weight: 600;
        color: #040404;
        margin-top: 45px;
      }
      .tip {
        display: inline-block;
        margin-top: 30px;
        font-size: 18px;
        font-weight: 400;
        color: #242831;
        line-height: 1.2;
        width: 196px;
        text-align: left;
      }
    }
  }
}
.info-block-5 {
  height: 699px;
  box-sizing: border-box;
  background-color: #1D2129;
  padding: 60px 45px 60px 39px;
  .w-1200 {
    position: relative;
  }
  .title {
    font-size: 48px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .info-box {
    .type-box {
      overflow: hidden;
      margin-top: 145px;
      .type-item {
        float: left;
        box-sizing: border-box;
        text-align: center;
        padding: 20px 20px 26px;
        border-radius: 5px;
        &:hover{
          background-color: rgba(255,255, 255, 0.05);
          cursor: pointer;
        }
        img {
          height: 25px;
        }
        p {
          font-size: 24px;
          font-weight: 400;
          color: #FFFFFF;
          margin-top: 20px;
        }
      }
    }
    .tip {
      margin-top: 60px;
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px;
      opacity: 0.6;
      width: 500px;
    }
  }
  .right-info-box {
    position: absolute;
    right: 66px;
    top: 6px;
    width: 500px;
    .right-ladder-img {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      height: 554px;
      width: 251px;
      background-image: url('~@/assets/images/right-ladder.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    .swiper5 {
      position: absolute;
      left: 0;
      padding-top: 103px;
      width: 280px;
      height: 400px;
      border-radius: 5px;
      ::v-deep .swiper-pagination-fraction {
        position: absolute;
        top: 129px;
        left: 10px;
        text-align: left;
        font-size: 24px;
        font-weight: 400;
        color: #D8DEE2;
        line-height: 24px;
        .swiper-pagination-current{
          font-size: 48px;
          font-weight: 400;
          color: #D8DEE2;
          line-height: 24px;
          position: relative;
          right: -7px;
        }
        .swiper-pagination-total {
          position: relative;
          left: -5px; 
        }
      }
    }
    .type-swiper-box {
      width: 280px;
      height: 400px;
      background-color: #fff;
      padding: 20px;
      box-shadow: border-box;
      .title {
        padding-top: 60px;
        font-size: 30px;
        font-weight: 500;
        color: #1D2129;
        line-height: 24px;
        margin-bottom: 43px;
        position: relative;
        display: inline-block;
        &:after {
          content: '';
          position: absolute;
          width: 48px;
          height: 4px;
          left: 50%;
          margin-left: -24px;
          bottom: -24px;
          background-image: url('~@/assets/images/line.png');
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .content {
        font-size: 14px;
        font-weight: 400;
        color: #535353;
        line-height: 28px;
        padding-right: 71px;
      }
    }
  }
  
}
.info-block-6 {
  padding: 69px 0 35px;
  .title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #040404;
    margin-bottom: 60px;
  }
  .info-box {
    overflow: hidden;
    padding-bottom: 10px;
    .info-item {
      float: left;
      width: 570px;
      height: 372px;
      background: #FFFFFF;
      border-radius: 2px;
      overflow: hidden;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      >img {
        width: 570px;
      }
      .content-box{
        padding: 40px;
        >img {
          float: left;
        }
      } 
      .text-box {
        margin-left: 117px;
        .name {
          font-size: 24px;
          font-weight: 600;
          color: #040404;
          line-height: 28px;
          margin-bottom: 19px;
        }
        .describe {
          font-size: 16px;
          font-weight: 600;
          color: #686868;
          line-height: 20px;
        }
        .times-up-box {
          margin-top: 38px;
          overflow: hidden;
          .times-up-item{
            width: 50%;
            float: left;
            strong {
              font-size: 36px;
              font-weight: 400;
              color: #1A2B80;
            }
            p {
              font-size: 16px;
              font-weight: 600;
              color: #686868;
              line-height: 28px;
              margin-top: 9px;
            }
          }
        }
      }
      &:first-child {
        margin-left: 21px;
        margin-right: 19px;
      }
    }
  }
  .dynamic-box {
    overflow: hidden;
    margin-top: 50px;
    .dynamic-item {
      float: left;
      width: 33.3333%;
      box-sizing: border-box;
      padding: 0 32px;
      position: relative;
      .tag {
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        color: #9AA1AC;
        // width: 59px;
        padding: 0 5px;
        height: 24px;
        background: #F5F7FA;
        border-radius: 2px;
        margin-bottom: 15px;
        text-align: center;
        line-height: 24px;
      }
      .name {
        font-size: 18px;
        font-weight: 400;
        color: #040404;
        margin-bottom: 32px;
      }
      .describe {
        font-size: 14px;
        font-weight: 400;
        color: #5A5A5A;
        line-height: 20px;
      }
      &:not(:last-child):after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 123px;
        background-color: #ddd;
      }
    }
  }
}
</style>
